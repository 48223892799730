import { Feature } from '@/config/feature';
import { Brand } from '@/lib/gql/graphql';

export enum ResourceTypename {
  Article = 'Article',
  ClickThroughArticle = 'ClickThroughArticle',
  Page = 'ModelDataPerRouteResponse',
  // @todo add all resource types
}

export enum CookieKeys {
  mtid = 'mtid',
  zinioAccessToken = 'zat',
}

export enum SessionStorageKey {
  ScrollToComments = 'scrollToComments',
}

export enum ReferenceId {
  Comments = 'comments',
}

export enum BrandDomain {
  Motortrend = 'motortrend.com',
  HotRod = 'hotrod.com',
}

export enum Header {
  Brand = 'x-brand',
  Domain = 'x-domain',
}

export const RelaunchBrands = [Brand.Hotrod] as string[];
export const IndexPageRoutes = [
  'homepage',
  ...(Feature.HotRodRelaunch ? RelaunchBrands : []),
] as string[];

export enum AlternateVertical {
  Automobile = 'Automobile',
  FourWheeler = 'Four Wheeler',
  Hotrod = 'HOT ROD',
  Lowrider = 'Lowrider',
  Motortrend = 'MotorTrend',
  Mustang360 = 'Mustang 360',
  MustangAndFords = 'Mustang and Fords',
  SuperChevy = 'Super Chevy',
  SuperStreet = 'Super Street',
  TruckTrend = 'Truck Trend',
}

// In the CMS, a brand vertical value can user facing, therefore there are two
// possible `vertical` values.
export const BrandVertical = {
  Automobile: [Brand.Automobilemag, AlternateVertical.Automobile],
  FourWheeler: [Brand.Fourwheeler, AlternateVertical.FourWheeler],
  HotRod: [Brand.Hotrod, AlternateVertical.Hotrod],
  Lowrider: [Brand.Lowrider, AlternateVertical.Automobile],
  MotorTrend: [Brand.Motortrend, AlternateVertical.Motortrend],
  Mustang360: [AlternateVertical.Mustang360],
  MustangAndFords: [Brand.Mustangandfords, AlternateVertical.MustangAndFords],
  SuperChevy: [Brand.Superchevy, AlternateVertical.SuperChevy],
  SuperStreet: [Brand.Superstreetonline, AlternateVertical.SuperStreet],
  TruckTrend: [Brand.Trucktrend, AlternateVertical.TruckTrend],
} as Record<string, string[]>;

// Map over the `RelaunchBrands` array and return an array of the possible
// values editors might use when populating the path or url field in the CMS.
export const BrandPathVariants = (): string[] =>
  RelaunchBrands.map((brand) => [
    `/${brand}`,
    `${brand}/`,
    `/${brand}/`,
    brand,
  ]).flat();

export const LinkAlias = {
  Account: 'account',
  CreateAccount: 'create-account',
  MyGarage: 'my-garage-web',
  SignIn: 'sign-in',
  SignOut: 'sign-out',
};

export const BrandURLAlias = {
  HotRodURLAlias: 'hot-rod',
};

export const CollectionAlias = {
  UserMenu: 'user-menu-web',
};

// Seconds
export const MinuteInSeconds = 60;
export const HourInSeconds = MinuteInSeconds * 60;
export const DayInSeconds = HourInSeconds * 24;
export const WeekInSeconds = DayInSeconds * 7;
// Milliseconds
export const SecondInMilliseconds = 1000;
export const MinuteInMilliseconds = SecondInMilliseconds * 60;
export const HourInMilliseconds = MinuteInMilliseconds * 60;
export const DayInMilliseconds = HourInMilliseconds * 24;

export enum DataTestId {
  RelatedModels = 'RelatedModels',
  // @todo implement these instead of strings
  GalleryImageContent = 'imageContent',
  GalleryBackButtonSmall = 'BackLinkButton-Small',
  GalleryBackButtonMedium = 'BackLinkButton-Medium',
  StickyAdContent = 'ad-content',
  AffiliateMarketingCarousel = 'affiliateMarketingCarousel',
}

export enum LocalStorageKey {
  AIChatbotMessages = 'ai-chatbot-messages',
}
